import {FormField} from './formField';
import React, {useState} from 'react';
import axios from 'axios';
import LoadingSpinner from '../assets/images/LoadingSpinner';
import Check from '../assets/images/Check';
import FormCheckbox from './formCheckbox';

const NewsletterForm = (props) => {
  const {headline, description, source, city, showCityInput=false, showBetaInput=false} = props;

  const LoadingButton = () => {
    return(
      <button
        className="btn bg-primary text-white hover:bg-primaryHover mx-1 my-1 w-56 inline-flex cursor-wait"
        type="button"
      >
        <LoadingSpinner /> Senden
      </button>
    )
  }

  const SendButton = () => {
    return(
      <button
        className="btn bg-primary text-white hover:bg-primaryHover mx-1 my-1 w-56 focus:bg-primaryHover"
        type="submit"
      >
        Jetzt anmelden
      </button>
    )
  }

  const ReadyButton = () => {
    return(
      <button
        className="btn bg-green-600 hover:bg-green-600 text-white mx-1 my-1 w-56 inline-flex cursor-default"
        type="button"
      >
        <Check /> Angemeldet
      </button>
    )
  }

  const inputDefaults = {
    name: '',
    email: '',
    city: city ? city : '',
    source: source,
    betaUser: false,
  }

  // local state - form controller
  const [input, setInput] = useState({...inputDefaults})
  const [errors, setErrors] = useState(new Set());
  const [forceUpdate, setForceUpdate] = useState(1);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const inputChangeHandler = (event) => {
    const key = event.target.name;
    const value = event.target.value;
    setInput({
      ...input,
      [key]: value,
    })
    // if error exits, we want a direct re-evaluation. otherwise wait until blur
    errors.has(key) ? validateInput(event.target) : null
  }

  const checkboxChangeHandler = (event) => {
    const key = event.target.name
    const value = event.target.checked
    setInput({
      ...input,
      [key]: value,
    })
    // validate on every change
    validateInput({name:key, value:value})
  }

  const inputBlurHandler = (fieldId) => {
    const target = {name: fieldId, value: input[fieldId]}
    validateInput(target)
    //setForceUpdate(forceUpdate+1)
  }

  const handlers = {
    inputChangeHandler: inputChangeHandler,
    inputBlurHandler: inputBlurHandler,
    checkboxChangeHandler: checkboxChangeHandler,
    input: input,
    errors: errors,
  }

  const validateForm = () => {
    for(const field in inputDefaults) {
      validateInput({name:field, value:input[field]})
    }
    setForceUpdate(forceUpdate+1) //hack to force rerender to show errors
  }

  const validateInput = (target) => {
    if(validationRuleContainsError(target.name, target.value)) {
      setErrors(errors => errors.add(target.name))
    } else {
      const newErrors = errors
      newErrors.delete(target.name)
      setErrors(newErrors)
    }
  }

  const validationRuleContainsError = (name, value) => {
    let error;
    switch (name) {
      case 'email':
        if (typeof(value) === 'undefined' || value === null || !RegExp("^.+@.+(\\.).+$").test(value)) error = true; break;
      default:
        error = false;
    }
    return error;
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    await validateForm()
    if(errors.size > 0) {
      console.log(errors)
    } else {
      console.log('ok')
      postMessage(input)
    }
  }

  const postMessage = (payload) => {
    setLoading(true)
    axios.post('/api/newsletter',payload)
      .then ( response => {
        console.log('message sent')
        setSuccess(true)
        setLoading(false)
        return true
      })
      .catch(error => {
        console.log('API error')
        setSuccess(false)
        setLoading(false)
        return false
      });
  }

  let formButton = <SendButton />
  if(loading) formButton = <LoadingButton />
  if(success) formButton = <ReadyButton />

  return (
    <form id="contact_form" onSubmit={handleSubmit} autoComplete="on" className="w-full border border-secondaryLightGray rounded-md bg-gray-50 py-6 px-8">
      { headline ? <h3 className="text-3xl font-light mb-2">{headline}</h3> : null }
      { description ? <p className="mb-8 font-light">{description}</p> : null }
      <div className="flex justify-end text-xs text-gray-500 mb-4 mx-4">*Pflichtfeld</div>
      {FormField({
        fieldId: 'email',
        fieldName:'E-Mail',
        required: true,
        handlers: handlers,
        errorText: 'Bitte gib eine gültige E-Mail-Adresse an.'
      })}
      { showCityInput ? FormField({
        fieldId: 'city',
        fieldName:'Stadt',
        required: false,
        handlers: handlers,
      }) : null }
      { showBetaInput ? FormCheckbox({
        fieldId: 'betaUser',
        fieldName:<div><b>Pilotkunde</b> - Ich möchte mich als Pilotkunde vormerken lassen und attraktive “Pre-Release” Angebote erhalten.<br />
          Sei der Erste in deiner Stadt! Jetzt kostenlos & unverbindlich auf die Warteliste eintragen.</div>,
        handlers: handlers,
      }) : null}
      <div className="text-xs font-light py-6">
        Die Einwilligung zum Newsletter kann jederzeit wiederufen werden. Mehr dazu findest du in
        unserer <a href="/datenschutz/" className="underline hover:text-primaryHover">Datenschutzerklärung</a>.
      </div>
      <div>{formButton}</div>
    </form>
  )
}

export default NewsletterForm

